.verseoftheday span {
    font-family: tamil;
	text-indent: 0px;
	line-height: 1.6;
    font-weight: lighter;
  }

  .verseoftheday p {
    font-family: tamil;
	text-indent: 0px;
	line-height: 1.6;
    font-weight: lighter;
  }

  .verseoftheday{
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1000px;
	flex-direction: column;
    padding: 0% 5%;
	border-radius: 10px;
    background-color: rgba(61, 52, 52, 0.05);
	background-image: url('./assets/profile-bg.webp');
	background-size: 100% 100%;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3),
        0 15px 15px rgba(0, 0, 0, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sharevod {
    display: inline-block;
}

.btn {
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
}

.btn:last-child {
	margin-right: 0;
}

/* STYLES RELATED TO EXPERIMENT BUTTONS */

/* Map for button styles */
.btn-warning {
	--color: #000000;
	--background-color: #87e1fc;
	--border-color: darken(#d8d8d8, 7.5%);
	color: var(--color);
	font-family: inherit;
	background-color: var(--background-color);
	padding: 0.2rem 0.7rem;
	border: solid 1px var(--border-color);
	outline: none;
	position: relative;
	border-radius: 0.5rem;
	user-select: none;
	margin: auto 10px;
	box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4), 0 -0.3rem 0.6rem rgba(0, 0, 0, 0.2) inset;
	transition: box-shadow 64ms ease-out;
}

.btn-warning:after {
	content: "";
	background-color: #ffffff;
	width: 75%;
	height: 12.5%;
	position: absolute;
	top: 0.15rem;
	left: 12.5%;
	border-radius: 50%;
	filter: blur(0.15rem);
	transition: opacity 64ms ease-out;
}

.btn-warning:active {
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.4), 0 0.4rem 1rem rgba(0, 0, 0, 0.3) inset;
	&:after {
		opacity: 0;
	}
}