.brandh{
  font-family: 'thamil';
  font-size: 45px;
  line-height: 20px;
  color: white;
  text-shadow: 2px 2px 25px #bb00ff;
}



body{
  background-size: 100% 100%;
  color: white;
}

.article{
  -webkit-user-select: none;
  user-select: none;
}

.table-container {
  padding: 10px;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

table {
  border: 1px solid #ffffff !important;
  min-width: 800px; /* Should be wider than container */
  white-space: nowrap; /* Prevents line breaks */
}

@media screen and (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
  }
}

.article p, .article li, .article span, .article strong, .article b{
  font-family: tamil;
  line-height: 1.7;

}

.article h1{
  text-align: center;
  margin-bottom: 10px;
  font-weight: lighter;
}


.article h2{
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: lighter;

}

.article h3{
  text-align: start;
  color: 	#5eff00;
  font-size: 27px;
  margin-bottom: 5px;
  font-weight: lighter;
}



b{
  color: rgb(250, 118, 250);
}

.article h4{
  color: #fc6559;
  font-family: tamil;
  font-weight: lighter;
}

.article h5{
  color: #ff89d8;
  font-family: tamil;
  font-weight: lighter;
}

.article h6{
  color: #c8ff00;
  font-family: tamil;
  font-size: 25px;
  font-weight: lighter;
}


.article_tam .p{
  font-weight: 500;
  font-family: Tamil;
}

.article_tam h1{
  text-align: center;
  font-weight: 500;
}


.article_tam h2{
  text-align: start;
  font-weight: 500;
}

.article_tam h3{
  font-weight: 500;
  text-align: start;
}


@media (max-width: 750px) {
  body{
    margin: 0% 0%;
    padding: 2%;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 650px) {
  .brandh{
    font-size: 25px;
  }

  .left img{
      width: 60px;
      height: 60px;
  }

  .right{
    flex-direction: column;
    bottom: 10px;
    left: -15px;
  }

  .brands{
    height: 30px;
    width: 120px;
    font-size: 15px;
  }
}

.brands{
  font-size: 18px;
  margin-top: 20px;
  height: 50px;
  width: 150px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.452);
}

.brand{
  margin-left: 8px;
}

.left{
  display: flex;
  align-items: center;
}

.header{
  margin-top: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 120px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.123);
  padding-bottom: 50px;
  background-position: center;
  background-color: #00ffff00;
}

.right{
  display: flex;
  position: relative;
  align-items: center;

}
@font-face {
  font-family: chapter;
  src: url(./sundaram18.ttf);
}
.tes{
  display: flex;
  font-family: chapter;
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 500;
}

.vr {
  width: 5px;
  height: 100%;
  border: none;
  background: linear-gradient(0deg, #ff5733, yellow, #400447, #33ff57, #5733ff);
  background-size: 100% 200%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: 0% 100%;
  }
}

@media screen and (max-width: 750px) {
  .testiment{
    flex-direction: column;
    width: 100%;

  }

  .old{
    width: 100%;
    display: block;
    padding: 0px;
    margin: 5%;
  }

  .new{
    width: 90%;
    margin: 5%;
  }
}

.link{
  width: 550px;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  background-color: #ffffff;
  box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.438);
  border-radius: 50px;
  margin: 2%;
}



.link > li{
  list-style: none;
  font-weight: bolder;
  border: 2px solid rgba(168, 3, 53, 0.979);
  font-size: 20px;
  padding: 2%;
  text-decoration: none;
  transition: 1s;
  border-radius: 8px;
}

.link > li:hover{
  list-style: none;
  font-weight: bolder;
  background-image: linear-gradient(90deg,
    rgba(1, 255, 179, 0.979),rgb(255, 251, 0))
  ;
  color: white;
  font-size: 20px;
  padding: 2%;
  text-decoration: none;
  border-radius: 8px;
}

.active-link{
  list-style: none;
  font-weight: bolder;
  background-image: linear-gradient(
    90deg,
    rgba(1, 255, 179, 0.979),
    rgb(255, 251, 0)
  );
  color: white;
  font-size: 20px;
  padding: 2%;
  text-decoration: none;
  border-radius: 8px;
}

.link li:nth-child(1){
  border-radius: 50px 8px 8px 50px;
}

.link li:nth-child(5){
  border-radius: 8px 50px 50px 8px;
}

.nav{
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.share{
  margin-left: 100px;
}

* {
	margin: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}
.container {
	position: relative;
	display: flex;
  border-radius: 15px;
	align-items: center;
	min-height: 65px;
	min-width: 50%;
	flex-wrap: wrap;
	justify-content: space-evenly;
}
.container .btn {
  position: relative;
  top: 0;
  left: 0;
  font-size: 16px;
  width: 135px;
  height: 35px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .btn a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
  font-size: smaller;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	padding: 10px;
	letter-spacing: 1px;
	text-decoration: none;
	overflow: hidden;
	color: #fff;
	font-weight: 400px;
	z-index: 1;
	transition: 0.5s;
	backdrop-filter: blur(15px);
}


.container .btn:hover a {
	letter-spacing: 3px;
}


.container .btn a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
	transform: skewX(45deg) translate(0);
	transition: 0.5s;
	filter: blur(0px);
}
.container .btn:hover a::before {
	transform: skewX(45deg) translate(200px);
}
.container .btn::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	bottom: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.container .btn:hover::before /*lightup button*/ {
	bottom: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}

.container .btn::after {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.container .btn:hover::after /*lightup button*/ {
	top: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}
.container .btn:nth-child(1)::before, /*chnage 1*/
.container .btn:nth-child(1)::after {
	background: #ff1f71;
	box-shadow: 0 0 5px #ff1f71, 0 0 15px #ff1f71, 0 0 30px #ff1f71,
		0 0 60px #ff1f71;
}
.container .btn:nth-child(2)::before, /* 2*/
.container .btn:nth-child(2)::after {
	background: #2db2ff;
	box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
		0 0 60px #2db2ff;
}
.container .btn:nth-child(3)::before, /* 3*/
.container .btn:nth-child(3)::after {
	background: #1eff45;
	box-shadow: 0 0 5px #1eff45, 0 0 15px #1eff45, 0 0 30px #1eff45,
		0 0 60px #1eff45;
}

.container .btn:nth-child(4)::before, /* 3*/
.container .btn:nth-child(4)::after {
  background: #cb2ced;
  box-shadow: 0 0 5px #cb2ced, 0 0 15px #cb2ced, 0 0 30px #cb2ced, 0 0 60px #cb2ced;
}
.container .btn:nth-child(5)::before, /* 3*/
.container .btn:nth-child(5)::after {
	background: #fc6559;
	box-shadow: 0 0 5px #fc6559, 0 0 15px #fc6559, 0 0 30px #fc6559,
		0 0 60px #fc6559;
}

.container .btn:nth-child(6)::before, /* 3*/
.container .btn:nth-child(6)::after {
  background: #fff700;
  box-shadow: 0 0 5px #fff700, 0 0 15px #fff700, 0 0 30px #fff700, 0 0 60px #fff700;
}

/* CSS */
.button-85 {
  padding: .4em 1em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;

}

.chap-link {
	position: relative;
	display: flex;
  border-radius: 15px;
	justify-content: center;
	align-items: center;
	min-width: 90%;
  font-size: 20px;
	display: flex;
	flex-wrap: wrap;
}
.chap-link .btn {
	position: relative;
	top: 0;
	left: 0;
	width: 45px;
	height: 45px;
	margin: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.chap-link .btn a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
  font-size: smaller;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	padding: 10px;
	letter-spacing: 1px;
	text-decoration: none;
	overflow: hidden;
	color: #fff;
	font-weight: 400px;
	z-index: 1;
	transition: 0.5s;
	backdrop-filter: blur(15px);
}




.chap-link .btn:hover a {
	letter-spacing: 3px;
}


.chap-link .btn a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
	transform: skewX(45deg) translate(0);
	transition: 0.5s;
	filter: blur(0px);
}
.chap-link .btn:hover a::before {
	transform: skewX(45deg) translate(200px);
}
.chap-link .btn::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	bottom: -5px;
	width: 30px;
	height: 0px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.chap-link .btn:hover::before /*lightup button*/ {
	bottom: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}

.chap-link .btn::after {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: -5px;
	width: 30px;
	height: 0px;
  background:  #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.chap-link .btn:hover::after /*lightup button*/ {
	top: 0;
	width: 10px;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}


.sidedraw{
  display: none;
  padding: 15px;
}

.mobile-nav {
	position: relative;
	display: flex;
  border-radius: 15px;
	justify-content: space-evenly;
	align-items: center;
	min-height: 260px;
	min-width: 40%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .sidedraw{
    display: block;
  }
}
.mobile-nav .btn {
	position: relative;
	top: 0;
	left: 0;
	width: 135px;
	height: 35px;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.mobile-nav .btn a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
  font-size: smaller;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	padding: 10px;
	letter-spacing: 1px;
	text-decoration: none;
	overflow: hidden;
	color: #fff;
	font-weight: 400px;
	z-index: 1;
	transition: 0.5s;
	backdrop-filter: blur(15px);
}

.center{
  display: flex;
  justify-content: center;
}

hr{
  height: 1px;
}

.hr {
  height: 0.5px;
  width: 100%;
  border: none;
  background: linear-gradient(90deg, #aa2d1083, #7a7a0c83, #6b0a7583, #0e812383, #260f8b83);
  background-size: 200% 100%;
  animation: gradientAnimation 7s linear infinite;
}

.darkhr{
  height: 0.5px;
  width: 100%;
  border: none;
  background: linear-gradient(90deg, #ff5733, yellow, #400447, #33ff57, #5733ff);
  background-size: 200% 100%;
  animation: gradientAnimation 7s linear infinite;
}


.versehr{
  
  width: 80%;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: 100% 0%;
  }
}

.mobile-nav .btn:hover a {
	letter-spacing: 3px;
}


.mobile-nav .btn a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
	transform: skewX(45deg) translate(0);
	transition: 0.5s;
	filter: blur(0px);
}
.mobile-nav .btn:hover a::before {
	transform: skewX(45deg) translate(200px);
}
.mobile-nav .btn::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	bottom: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.mobile-nav .btn:hover::before /*lightup button*/ {
	bottom: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}

.mobile-nav .btn::after {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.mobile-nav .btn:hover::after /*lightup button*/ {
	top: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}
.mobile-nav .btn:nth-child(1)::before, /*chnage 1*/
.mobile-nav .btn:nth-child(1)::after {
	background: #ff1f71;
	box-shadow: 0 0 5px #ff1f71, 0 0 15px #ff1f71, 0 0 30px #ff1f71,
		0 0 60px #ff1f71;
}
.mobile-nav .btn:nth-child(2)::before, /* 2*/
.mobile-nav .btn:nth-child(2)::after {
	background: #2db2ff;
	box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
		0 0 60px #2db2ff;
}
.mobile-nav .btn:nth-child(3)::before, /* 3*/
.mobile-nav .btn:nth-child(3)::after {
	background: #1eff45;
	box-shadow: 0 0 5px #1eff45, 0 0 15px #1eff45, 0 0 30px #1eff45,
		0 0 60px #1eff45;
}

.mobile-nav .btn:nth-child(4)::before, /* 3*/
.mobile-nav .btn:nth-child(4)::after {
  background: #cb2ced;
  box-shadow: 0 0 5px #cb2ced, 0 0 15px #cb2ced, 0 0 30px #cb2ced, 0 0 60px #cb2ced;
}
.mobile-nav .btn:nth-child(5)::before, /* 3*/
.mobile-nav .btn:nth-child(5)::after {
	background: #fc6559;
	box-shadow: 0 0 5px #fc6559, 0 0 15px #fc6559, 0 0 30px #fc6559,
		0 0 60px #fc6559;
}

.mobile-nav .btn:nth-child(6)::before, /* 3*/
.mobile-nav .btn:nth-child(6)::after {
  background: #fff700;
  box-shadow: 0 0 5px #fff700, 0 0 15px #fff700, 0 0 30px #fff700, 0 0 60px #fff700;
}

.nav img{
  padding: 10px;
  box-shadow: 1px 1px 15px rgba(128, 128, 128, 0.582);
  border-radius: 50%;
}

.chapterh{
  font-family: thamil;
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: lighter;
}

a:visited{
  text-decoration: none;
}

a{
  text-decoration: none;
}

.chapter{
  display: flex;
  flex-direction: column-reverse;
}

.introduction{
  width: 100%;
  font-family: Link;
}


.introduction span{
  font-family: Link;
  font-size: 18px;
}

.chapterl{
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
}

@media screen and (max-width: 950px) {
  .chapter{
    flex-direction: column-reverse;
  }
}

.mc{
  bottom: 50px;
  padding: 0px 20%;
}

.chapterl > div{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.chapno{
  position: relative;
  max-width: 60.56px;
  max-height: 60.56px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  font-size: larger;
  margin: 8px;
  background-color: white;
  border: 2px solid blueviolet;
  border-radius: 50%;
  transition: 0.2s;
  
}


.chapno  a{
  font-weight: bolder;
  text-decoration: none;
}


.chapno:hover{
  position: relative;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 8px;
  background-color: #ade4e6;
  border: none;
  box-shadow: 1px 1px 15px greenyellow,
  1px 1px 15px greenyellow,
  1px 1px 15px greenyellow,
  1px 1px 15px greenyellow;
  border-radius: 50%;
  
}


.old{
  width: 98%;
  padding: 1%;
  overflow-x: hidden;
}
.new{
  width: 98%;
  padding: 1%;
  overflow-x: hidden;
}

.testiment{
  overflow-x: hidden;
}

@media screen and (max-width: 680px) {
  .chapno{
    font-size: 10px;
  }

  .chapterl{
    display: flex;
    justify-content: center;
  }

  body{
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .nav{
    display: none;
  }
}


.mobilenav{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background-color: #400447;
  padding: 15px;
  border-radius: 15px;
}

.mnt{
  display: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: center;
  
}

.mobilenav li{
  position: relative;
  list-style: none;
  width: 80%;
  text-align: center;
  padding: 5px;
  margin: 5px;
  background-image: linear-gradient(45deg, rgb(255, 0, 255) , rgb(0, 162, 255)) ;
  border-radius: 5px;
} 

.mobilenav a{
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
}


/* Add mobile navigation styles */


  
  @media (max-width: 768px) {
  .mnt {
    display: block; /* Show mobile navigation on smaller screens */
  }

  .nav{
    display: none;
  }
}


@font-face {
  font-family: Link;
  src: url(./ATM007.ttf);
}

.testiment span{
  font-family: Link;
}



.contact{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 5% 10%;
  font-size: large;
  width: 80%;
}

.contact > p > span{
  font-size: larger;
  font-family: 'Noticia Text', serif;
}


ul {
  list-style: none
}
a {
  text-decoration: none;
  color: white;
}

.generic-anchor {
	color: #8DB9ED;
	&:visited {
		color: #ccc;
	}
	&:hover {
		color: #ccc;
	}
}
.flex-rw {
  display: flex; 
  flex-flow: row wrap;
}
footer {
	background: linear-gradient(to bottom, black, #47042a);
  margin-top: auto;
  width: 100%;
}

body {
  padding: 0px;
}

.footer-list-top {
  width: 33.333%
}
.footer-list-top > li {
  text-align: center;
  padding-bottom: 10px
}
.footer-list-header {
  padding: 10px 0 5px 0;
  color: #fff;
  font: 2.3vw "Roboto", sans-serif;
}
.footer-list-anchor {
  font: 1.3em "Roboto", sans-serif;
  font-weight: 100;
}
.footer-social-section {
  width: 100%;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin-top: 5px;
}
.footer-social-section::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  border-top: 1px solid #ccc;
  width: calc(100% - 20px)
}
.footer-social-overlap {
  position: relative;
  z-index: 2;
	background: linear-gradient(to bottom, #6c3232, #47042a);
  border-radius: 15px;
  box-shadow: 1px 1px 15px grey;
  padding: 0 20px
}
.footer-social-connect {
  display: flex;
  align-items: center;
  font: 3.5em "Oswald", sans-serif;
  color: #fff
}
.footer-social-small {
  font-size: 0.6em;
  padding: 0px 20px;
  color: white;
}
.footer-social-overlap > a {
  font-size: 3em
}
.footer-social-overlap > a:not(:first-child) {
  margin-left: 0.38em
}
.footer-bottom-section {
  width: 100%;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 10px
}
.footer-bottom-section > div:first-child {
  margin-right: auto
}
.footer-bottom-wrapper {
  font-size: 1.5em;
  color: #fff
}
.footer-address {
  display: inline;
  font-style: normal
}

.privacy{
  font-size: 10px;
}

@media only screen and (max-width: 768px) {
  .footer-list-header {
    font-size: 2em
  }
  .footer-list-anchor {
    font-size: 1.1em
  }
  .footer-social-connect {
    font-size: 2.5em
  }
  .footer-social-overlap > a {
    font-size: 2.24em
  }
  .footer-bottom-wrapper {
    font-size: 1.3em
  }
}


@media only screen and (max-width: 568px) {
  main {
    font-size: 5em
  }
  .footer-list-top {
    width: 90%
  }
  .footer-list-header {
    font-size: 3em;
  }
  .footer-list-anchor {
    font-size: 1.5em
  }
  .footer-social-section {
    justify-content: center
  }
  .footer-social-section::after {
    top: 25%
  }
  .footer-social-connect {
    margin-bottom: 10px;
    padding: 0 10px
  }
  .footer-social-overlap {
    display: flex;
    justify-content: center;
  }
  .footer-social-icons-wrapper {
    width: 100%;
    padding: 0
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 20px;
  }
  .footer-bottom-section {
    padding: 0 5px 10px 5px
  }
  .footer-bottom-wrapper {
    text-align: center;
    width: 100%;
    margin-top: 10px
  }
}
@media only screen and (max-width: 480px) {
  .footer-social-overlap > a {
    margin: auto
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 0;
  }
  .footer-bottom-rights {
    display: block
  }
}
@media only screen and (max-width: 320px) {
  .footer-list-header {
    font-size: 2.2em
  }
  .footer-list-anchor {
    font-size: 1.2em
  }
  .footer-social-connect {
    font-size: 2.4em
  }
  .footer-social-overlap > a {
    font-size: 2.24em
  }
  .footer-bottom-wrapper {
    font-size: 1.3em
  }
}

.generic-anchor{
  color: white;
}


@media screen and (max-width: 768px) {
  .mc{
    padding: 0px 10px;
    margin: 0px;
  }

  .left{
    position: relative;
    left: 50px;
  }

  .share{
    margin-left: 20px;
    margin-right: 60px;
  }

  .introduction{
    padding: 0px 0px;
  }
  

  .share img{
    width: 25px;
    height: 25px;
  }

  .mb-5{
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 376px) {
  html{
    overflow-x: hidden;
  }
}




.hidecontent{
  display: none;
}

.content h1{
  color: #4D121D;
  margin: 5px;
}
@font-face {
  font-family: tamil;
  src: url(./Lohit-Tamil.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: thamil;
  src: url(./uniAmma-001.ttf) format('truetype');
  font-display: swap;
}

h1{
  font-family: thamil;
  font-weight: 100;
}

.testiment span{
  font-weight: lighter;
  font-family: tamil;
}

.old{
  border-right: 1px dotted darkgrey;
}

.content p{
  text-indent: 40px;
	line-height: 1.7;
  font-family: tamil;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 100;
}



.content li{
  font-family: tamil;
	line-height: 1.7;
}


.yellow_color{
  color: yellow;
}

.yellow_color:visited{
  color: yellow;
}

.greenish_blue_color{
  color: #00ffd5;
}

.greenish_blue_color:visited{
  color: #00ffd5;
}

.mc{
  
	background: rgba(61, 52, 52, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3),
  0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0% 18%;
  padding: 10px;
  display: block;
  border-radius: 10px;
}

body{
  background: black;
}



@media screen and (max-width: 768px) {
  .mc{
    margin: 0% 10%;
  }
}

@media screen and (max-width: 535px) {
  .mc{
    margin: 0% 2px;
  }
}

@media screen and (min-width: 425px) {
  .main-content{
    background-image: url(./assets/bg2.webp);
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 425px) {

  .content li{
    padding: 10px;
    text-indent: 40px;
  }

  .content p{
    text-indent: 40px;
    margin-bottom: 20px;
  }

  .mc{
    padding:2px;
  }
}

body{
  overflow-x: hidden;
}


.content{
  padding: 10px;
}


@media screen and (max-width: 425px) {
  .hide-on-mobile{
    display: none;
  }
}

.chapter .introduction .content h1{
  margin-bottom: 45px;
}